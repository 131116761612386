import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { TermsAndConditionsGuard } from './services/terms-and-conditions.guard';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import {AuthGuard} from './services/auth.guard';
import {TaCEnabledGuard} from './services/tac-enabled.guard';
import {UserLanguageResolver} from './resolvers/user-language.resolver';
import {SystemLanguageResolver} from './resolvers/system-language.resolver';
import {OutlookPluginComponent} from './outlook-plugin/outlook-plugin.component';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard, TermsAndConditionsGuard],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'terms_and_conditions',
    canActivate: [AuthGuard, TaCEnabledGuard],
    component: TermsAndConditionsComponent,
    resolve: {
      useLanguage: UserLanguageResolver,
    },
  },
  {
    path: 'static',
    loadChildren: () => import('./pages-lite/pages-lite.module')
      .then(m => m.PagesLiteModule),
    resolve: {
      useLanguage: SystemLanguageResolver,
    },
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
      .then(m => m.AwrAuthModule),
  },
  {
    path: 'authz',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NbLoginComponent,
      },
      {
        path: 'login',
        component: NbLoginComponent,
      },
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },
  {
    path: 'outlook-plugin',
    component: OutlookPluginComponent,
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
  onSameUrlNavigation: 'ignore',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
