import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <div class='d-flex justify-content-between w-100'>
    <span class="created-by by-zinad">
      <img src="/ui/assets/awr/images/zinad.png" alt="zinad" style="width: 12px;" class="mx-1">
      <b style="font-weight: 400;"> <sub>{{'Powered by' | translate}} <a href="https://zinad.net/" class="by-zinad"
      target="_blank">{{'ZINAD' | translate}}</a></sub></b> </span>
      <div class="privacy">
      <span class="mx-3 my-privacy" [hidden]="true"><a [routerLink]="'/static/privacy-policy'" target="_blank">{{'privacy policy' | translate}}</a></span>
      <span class="created-by by-entrench"> {{'Release No.' | translate}} {{releaseNumber}}</span></div>
      </div>
  `,
  // <div class="socials">
  //     <a href="#" target="_blank" class="ion ion-social-github"></a>
  //     <a href="#" target="_blank" class="ion ion-social-facebook"></a>
  //     <a href="#" target="_blank" class="ion ion-social-twitter"></a>
  //     <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
  //   </div>
})
export class FooterComponent implements OnInit {
  constructor() { }
  year: number = (new Date()).getFullYear();
  releaseNumber: string;
  ngOnInit() {
    this.releaseNumber = '5.2';
  }
}

