import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbLayoutDirection, NbLayoutDirectionService, NbThemeService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

interface ThemePalette {
  popup_bg: string;
  popup_text: string;
  button_bg: string;
  button_text: string;
}

/**
 * !!!!! IMPORTANT !!!!!
 * Whenever anybody add a new theme, to get the look and feel of this theme, kindly add its configuration
 * to this object
 */
const themePalette: { [key: string]: ThemePalette } = {
  default: {
    popup_bg: '#1e76a3',
    popup_text: '#fff',
    button_bg: '#1e76a3',
    button_text: '#fff',
  },
  dark: {
    popup_bg: '#636edf',
    popup_text: '#fff',
    button_bg: '#636edf',
    button_text: '#fff',
  },
  cosmic: {
    button_bg: '#1b1b38',
    button_text: '#fff',
    popup_bg: '#1b1b38',
    popup_text: '#fff',
  },
  corporate: {
    button_bg: '#36f',
    button_text: '#fff',
    popup_bg: '#36f',
    popup_text: '#fff',
  },
  sec: {
    button_bg: '#f18a1e',
    button_text: '#fff',
    popup_bg: '#fff',
    popup_text: '#1e4691',
  },
  mof: {
    button_bg: '#015b4f',
    button_text: '#fff',
    popup_bg: '#fff',
    popup_text: '#015b4f',
  },
  imctc: {
    button_bg: '#c3a031',
    button_text: '#fff',
    popup_bg: '#fff',
    popup_text: '#333D48',
  },
  moh: {
    button_bg: '#008755',
    button_text: '#fff',
    popup_bg: '#fff',
    popup_text: '#1e4691',
  },
  royal_protocol: {
    button_bg: '#008755',
    button_text: '#fff',
    popup_bg: '#fff',
    popup_text: '#1e4691',
  },
};

@Component({
    selector: 'ngx-cookie-consent',
    template: '<div></div>',
})
export class CookieConsentComponent implements OnInit, OnDestroy {
    /** Begin Cookie Consent config */
    // private popupOpenSubscription: Subscription;
    // private popupCloseSubscription: Subscription;
    private initializeSubscription: Subscription;
    // private statusChangeSubscription: Subscription;
    // private revokeChoiceSubscription: Subscription;
    // private noCookieLawSubscription: Subscription;
    /** End Cookie Consent config */

    constructor(
      private ccService: NgcCookieConsentService,
      private nbThemeService: NbThemeService,
      private translateService: TranslateService,
      private directionService: NbLayoutDirectionService,
    ) {}

    ngOnInit(): void {
      this.nbThemeService.getJsTheme().subscribe(({name}) => this.setCcThemeConfig(name));
      this.translateService.onDefaultLangChange.subscribe(() => this.setCcLanguageConfig());
      this.directionService.onDirectionChange().subscribe((dir) => this.setCcPositionConfig(dir));


    /** Begin Cookie consent */
    // subscribe to cookieconsent observables to react to main events
    // this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
    //   // you can use this.ccService.getConfig() to do stuff...
    //   console.log(`popupOpenSubscription: ${this.ccService.getConfig()}`)
    // });

    // this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
    //   // you can use this.ccService.getConfig() to do stuff...\
    //   console.log(`popupCloseSubscription: ${this.ccService.getConfig()}`)
    // });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
        },
      );

      // this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      //   (event: NgcStatusChangeEvent) => {
      //     // you can use this.ccService.getConfig() to do stuff...
      //     console.log(`statusChangeSubscription: ${this.ccService.getConfig()}`)
      //   }
      // );

      // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      //   () => {
      //     // you can use this.ccService.getConfig() to do stuff...
      //     console.log(`revokeChoiceSubscription: ${this.ccService.getConfig()}`)

      //   }
      // );

      // this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      //   (event: NgcNoCookieLawEvent) => {
      //     // you can use this.ccService.getConfig() to do stuff...
      //     console.log(`noCookieLawSubscription: ${this.ccService.getConfig()}`)
      //   }
      // );
    }

    setCcLanguageConfig() {
      this.ccService.getConfig().content.header = this.translateService.instant('cookie.header');
      this.ccService.getConfig().content.message = this.translateService.instant('cookie.message');
      this.ccService.getConfig().content.dismiss = this.translateService.instant('cookie.dismiss');
      this.ccService.getConfig().content.allow = this.translateService.instant('cookie.allow');
      this.ccService.getConfig().content.deny = this.translateService.instant('cookie.deny');
      this.ccService.getConfig().content.link = this.translateService.instant('cookie.link');
      this.ccService.getConfig().enabled = true;
      this.reRenderCc();
    }

    setCcThemeConfig(themeName: string) {
      if (themePalette[themeName]) {
        this.ccService.getConfig().palette.popup.background = themePalette[themeName].popup_bg;
        this.ccService.getConfig().palette.popup.text = themePalette[themeName].popup_text;
        this.ccService.getConfig().palette.button.background = themePalette[themeName].button_bg;
        this.ccService.getConfig().palette.button.text = themePalette[themeName].button_text;
      } else {
        /**
         * This is some dummy data based on default theme,
         *
         * @todo: remove the theming from the cookie consent, and instead add it to global theme options
         */
        this.ccService.getConfig().palette.popup.background = '#1e76a3';
        this.ccService.getConfig().palette.popup.text = '#fff';
        this.ccService.getConfig().palette.button.background = '#1e76a3';
        this.ccService.getConfig().palette.button.text = '#fff';
      }
      this.reRenderCc();
    }

    setCcPositionConfig(dir: NbLayoutDirection) {
      this.ccService.getConfig()['position'] = dir === NbLayoutDirection.LTR ? 'bottom-left' : 'bottom-right';
      this.ccService.destroy(); // remove previous cookie bar (with default messages)
      this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      this.reRenderCc();
    }

    private reRenderCc() {
      this.ccService.destroy(); // remove previous cookie bar (with default messages)
      this.ccService.init(this.ccService.getConfig()); // update config with translated messages
    }

    ngOnDestroy() {
        // unsubscribe to cookieconsent observables to prevent memory leaks
        // this.popupOpenSubscription.unsubscribe();
        // this.popupCloseSubscription.unsubscribe();
        this.initializeSubscription.unsubscribe();
        // this.statusChangeSubscription.unsubscribe();
        // this.revokeChoiceSubscription.unsubscribe();
        // this.noCookieLawSubscription.unsubscribe();
    }
}
