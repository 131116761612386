import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs/operators';
import { NbAuthService } from '../@framework/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const securityAdminComponents = ['suspected-emails', 'suspected-email-rules', 'suspected_email_tags', 'analyzer_settings'];

    return this.authService.isAdmin().pipe(
      tap(res => {
        if ((state.url.includes('pages/bread/model') || state.url.includes('pages/bread/new_model'))
          && securityAdminComponents.includes(route.queryParamMap.get('model'))
          && this.authService.isSecurityAdmin()) {
          return true;
        }
        if (!res) {
          this.router.navigate(['/pages/miscellaneous/notAuthorized']);
        } else {
          return true;
        }
      }),
    );
  }
}
