import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BreadService {
  csvTemplate: boolean;

  constructor(private http: HttpClient, private toastr: ToastrService, private translateService: TranslateService) { }

  getUser(): Observable<any> {
    return this.http.get('/app/user/0?ui_version=v3')
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return err;
        }),
      );
  }

  getUplaodMaxSize(): Observable<any> {
    return this.http.get('/app/lesson/max_size')
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return err;
        }),
      );
  }

  getPagedData(url, itemPerPage, pageNumber, orderBy, direction, options = null) {
    url = `${url}/${itemPerPage}/${pageNumber}/${orderBy}/${direction}`;
    return this.http.get(`/app/${url}`, {params: options})
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  getData(url, options = null): Observable<any> {
    const request: Observable<any> = options ? this.http.get(`/app/${url}`, {params: options}) : this.http.get(`/app/${url}`);
    return request
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }
deleteBatch(url, options = null) {
    url = `${url}`;
    return this.http.delete(`/app/${url}`, {params: options})
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }
  getDataUsingFullURL(url, options = null): Observable<any> {
    const request: Observable<any> = options ? this.http.get(`${url}`, {params: options}) : this.http.get(`${url}`);
    return request
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  createModel(url, model, options = null): Observable<any> {
     const response: Observable<any> = options ? this.http.post(`/app/${url}`, model, options) : this.http.post(`/app/${url}`, model);
    return response.pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  editModelWithFile(url, model): Observable<any> {
    return this.http.post(`/app/${url}`, model)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  metaConfig(): Observable<any> {
    return this.http.get(`/app/config/analytics-url`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return err;
        }),
      );
  }

  editModel(url, model, options = null): Observable<any> {
    const request: Observable<any> = options ? this.http.put(`/app/${url}`, model, options) : this.http.put(`/app/${url}`, model);
    return request
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }


  getPayload(url): Observable<any> {
    return this.http.get(`/app/${url}`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  passwordModel(url, model): Observable<any> {
    return this.http.post(`/app/${url}`, model)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  importModel(url): Observable<any> {
    return this.http.post(`/app/${url}`, null)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }


  bindModel(url): Observable<any> {
    return this.http.get(`/app/${url}`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  submitModel(url): Observable<any> {
    return this.http.put(`/app/${url}`, null)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  deleteModel(url): Observable<any> {
    return this.http.delete(`/app/${url}`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  confirmModel(url): Observable<any> {
    return this.http.get(`/app/${url}`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  confirmModelPost(url, params): Observable<any> {
    return this.http.post(`/app/${url}`, params)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  sendModel(url): Observable<any> {
    return this.http.post(`/app/${url}`, null)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }



  getSelectRemoteOptions(url): Observable<any> {
    return this.http.get(`/app/${url}`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return err;
        }),
      );
  }

  getMultipleCheckboxRemoteOptions(url): Observable<any> {
    return this.http.get(`/app/${url}`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return err;
        }),
      );
  }

  getCurrentModel(url, id?): Observable<any> {

    return this.http.get(`/app/${url}` + ((id) ? `/${id}` : ''))
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return err;
        }),
      );
  }

  getCurrentSubModel(url): Observable<any> {
    return this.http.get(`/app/${url}`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return err;
        }),
      );
  }

  searchWizard(url, data): Observable<any> {
    return this.http.post(`/app/${url}`, data)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return err;
        }),
      );
  }

  submitWizard(url, itemPerPage, pageNumber, orderBy, direction, data): Observable<any> {
    url = `${url}/${itemPerPage}/${pageNumber}/${orderBy}/${direction}`;
    return this.http.post(`/app/${url}`, data)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  removeWizard(url, data): Observable<any> {
    return this.http.post(`/app/${url}`, data)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

  getLanguage(): Observable<any> {
    return this.http.get('/app/language')
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return err;
        }),
      );
  }

  getSelectRemoteSelectedRelated(url): Observable<any> {
    return this.http.get(`/app/${url}`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return err;
        }),
      );
  }

  getRelatedTypeDetails(url): Observable<any> {
    return this.http.get(`/app/${url}`)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return err;
        }),
      );
  }

  getAnalyticsURL(id): Observable<any> {
    return this.http.get(`/app/analytics/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        return err;
      }),
    );
  }

  getAnalyticsInnerHTML(url) {
    return this.http.get(`${url}`, { responseType: 'text' }).toPromise()
      .then(res => res)
      .catch(err => {
        return err;
      });
  }

  getCurrentModelJSON(model): Observable<any> {
    return this.http.get(`/app/ui_model/${model}.json`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        return err;
      }),
    );
  }

  handleSuccessMessage(currentLangRTL, msg) {
    this.toastr.success(this.translateService.instant(`${msg}`), this.translateService.instant('success'),
      currentLangRTL ? { positionClass: 'toast-top-left', timeOut: 3000 } :
        { positionClass: 'toast-top-right', timeOut: 3000 });
  }

  handleErrorMessage(currentLangRTL, err) {

    if (err.error.hasOwnProperty('msg')) {
      const msg = err.error.msg;
      if (Array.isArray(msg)) {
        let errors = '';
        for (let i = 0; i < err.error.msg.length; i++) {
          errors += `${this.translateService.instant(`E${msg[i]}`)}<br />`;
        }

        this.toastr.error(errors, this.translateService.instant('Error'),
          {
            positionClass: currentLangRTL ? 'toast-top-left' : 'toast-top-right',
            timeOut: 3000,
            enableHtml: true,
          });

      } else {
        this.toastr.error(
          this.translateService.instant(`E${err.error.msg}`) + ' ' + (err.error.dynamic_data ? err.error.dynamic_data : ''),
          this.translateService.instant('Error'),
          currentLangRTL ? {positionClass: 'toast-top-left', timeOut: 3000} :
            {positionClass: 'toast-top-right', timeOut: 3000});
      }
    } else {
      this.toastr.error(
        this.translateService.instant(`${err.error}`),
        this.translateService.instant('Error'),
        currentLangRTL ? { positionClass: 'toast-top-left', timeOut: 3000 } :
          { positionClass: 'toast-top-right', timeOut: 3000 });
    }


  }

  getMailSettingsData(url, id): Observable<any> {
    return this.http.get(`/app/${url}/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        return err;
      }),
    );
  }

  getPhishpotMailSettingsData(id): Observable<any> {
    return this.http.get(`/app/phishpot/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        return err;
      }),
    );
  }

  getEmailServers(options = null): Observable<any> {
    return this.http.get(`/app/emailserver`, {params: options}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        return err;
      }),
    );
  }

  filterEmailTemplates(options = null): Observable<any> {
    return this.http.get(`/app/emailtemplates`, {params: options}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        return err;
      }),
    );
  }

  setTrainingCampaignEmailSettings(id, form): Observable<any> {
    return this.http.post(`/app/campaign/${id}/emailsettings`, form)
      .pipe(map((res: any) => {
        return res;
      }),
        catchError(err => {
          return err;
        }),
      );
  }

  getPhishpotEmailServers(): Observable<any> {
    return this.http.get(`/app/phishingemailserver`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      }),
    );
  }

  setPhishpotCampaignEmailSettings(id, form): Observable<any> {
    return this.http.post(`/app/phishpot/${id}/emailsettings`, form)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
  }

      trainingCampaignsStatus(): Observable<any> {
        return this.http.get(`/app/campaign/status`).pipe(
          map((res: any) => {
            return res;
          }),
          catchError(err => {
            return err;
          }),
        );
       }

      phishpotCampaignsStatus(): Observable<any> {
        return this.http.get(`/app/phishpot/status`).pipe(
          map((res: any) => {
            return res;
          }),
          catchError(err => {
            return err;
          }),
        );
       }

       getpdfReport(folder, pdfName): void {
        this.http.get(`/app/${folder}/${pdfName}`, {responseType: 'arraybuffer'},
         ).subscribe(response => {
          const blob = new Blob([response], { type: 'application/pdf'});
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${pdfName}.pdf`;
          link.click();
          // let pwa = window.open(url);
          // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          //     alert( 'Please disable your Pop-up blocker and try again.');
          // }
         });
        }
  getSMSProviders(): Observable<any> {
    return this.http.get(`/app/sms_providers`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        return err;
      }),
    );
  }

  rawPost(url, body): Observable<any> {
    return this.http.post(url, body);
  }

  getHtmlPage(url): Observable<any> {
    return this.http.get(url, { responseType: 'text' });
  }

  downloadFile(url) {
    return this.http.get('/app/' + url,
    {observe: 'response', responseType: 'blob'});
  }
}
