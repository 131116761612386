import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';
import {NbMenuService} from '@nebular/theme';
import {NbAuthService} from '../../../@framework/auth/public_api';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from '../../../services/profile.service';

const LOGOUT_ID = 'LOGOUT';
const PROFILE_ID = 'PROFILE';

@Component({
  selector: 'ngx-user-dropdown',
  styleUrls: ['./user-dropdown.component.scss'],
  templateUrl: './user-dropdown.component.html',
})
export class UserDropdownComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  user: any;
  avatar: any;
  userMenu: any;
  full_name: string = '';

  constructor(private http: HttpClient,
              private menuService: NbMenuService,
              private authService: NbAuthService,
              private translateService: TranslateService,
              private profileService: ProfileService,
              private router: Router) {
  }

  ngOnInit() {
    this.menuService.onItemClick().subscribe((x) => {
      if (x && x.item && x.item.data && x.item.data.id === LOGOUT_ID) {
        this.authService.logout('email');
      } else if (x && x.item && x.item.data && x.item.data.id === PROFILE_ID) {
        this.router.navigate(['/pages/profile']);
      }
    });

    this.http
    .get('/app/my/avatar')
    .pipe(first()).toPromise()
    .then(res => {
      if (res['base64_image'] !== '') this.avatar = res;
      this.setUserData();
    }).catch(err => {
      this.setUserData();
    });

    this.profileService.avatar.subscribe((avatar) => {
      this.user.picture = avatar.base64_image;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setUserData() {
    this.http.get('/app/user/0?ui_version=v3').pipe(first()).subscribe(res => {
      this.user = res;
      // default avatar
      this.user.picture = this.avatar ? this.avatar.base64_image : '/ui/assets/images/default-avatar_2.png';
      if (this.user.first_name && this.user.last_name) {
        this.full_name = this.user.first_name + ' ' + this.user.last_name;
      } else if (this.user.first_name) {
        this.full_name = this.user.first_name;
      } else if (this.user.last_name) {
        this.full_name = this.user.last_name;
      } else {
        this.full_name = this.user.username;
      }
      this.userMenu = [
        {
          title: this.translateService.instant('S45'),
          data: {id: LOGOUT_ID},
        },
      ];
    });
  }
}
