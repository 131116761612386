import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig = {
  enabled: false,
  autoOpen: false,
  cookie: {
    domain: window.location.hostname, // or 'your.domain.com'
  },
  position: 'bottom-left',
  revokable: false,
  palette: {
    popup: {
      background: '#fff',
      text: '#000',
    },
    button: {
      background: '#1e76a3',
      text: '#fff',
    },
  },
  theme: 'edgeless',
  type: 'info',
  content: {
    message: '',
    dismiss: '',
    allow: '',
    deny: 'X',
    link: 'Cookie',
    href: './static/cookie-policy',
  },
};
