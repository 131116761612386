import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'ngx-outlook-plugin',
  templateUrl: './outlook-plugin.component.html',
  styleUrls: ['./outlook-plugin.component.scss'],
})
export class OutlookPluginComponent {
  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      if (params.code && params.code.length > 0) {
        this.informCaller(params.code);
      }
    });
  }
  private informCaller(code: string) {
    const data = {
      type: 'phishing-auth-complete',
      accessToken: code,
    };

    window.opener.postMessage(data, '*');
  }

}
