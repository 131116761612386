import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule, NbBadgeModule, NbToggleModule,
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  LanguageComponent,
  NotificationsComponent,
  UserDropdownComponent,
  SearchInputComponent,
  TinyMCEComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  SanitizeHtmlPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import {DEFAULT_THEME} from './styles/theme.default';
import {COSMIC_THEME} from './styles/theme.cosmic';
import {CORPORATE_THEME} from './styles/theme.corporate';
import {DARK_THEME} from './styles/theme.dark';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HelpComponent } from './components/help/help.component';
import {TranslateModule} from '@ngx-translate/core';
import {SideMenuAvatarComponent} from './components/side-menu-avatar/side-menu-avatar.component';
import {RouterModule} from '@angular/router';
import { ThemeModeComponent } from './components/theme-mode/theme-mode.component';
import { LogoComponent } from './components/logo/logo.component';
import { LanguageViewComponent } from './components/language-view/language-view.component';
import { SEC_THEME } from './styles/theme.sec';
import { MOF_THEME } from './styles/theme.mof';
import { GREEN_THEME } from './styles/theme.green';
import { CITC_THEME } from './styles/theme.citc';
import { IMCTC_THEME } from './styles/theme.imctc';
import { MOH_THEME } from './styles/theme.moh';
import { ROYAL_PROTOCOL } from './styles/theme.royal.protocol';
import { GOSI_THEME} from './styles/theme.gosi';
import { MOE_THEME } from './styles/theme.moe';
import { MOM_THEME } from './styles/theme.mom';
import { ARAMCO_THEME } from './styles/theme.aramco';
import { GAMI_THEME } from './styles/theme.gami';
import { ETUAE_THEME } from './styles/theme.et-uae';
import { GARTNER_THEME } from './styles/theme.gartner';
import { SHC_THEME } from './styles/theme.shc';
import { NAZAHA_THEME } from './styles/theme.nazaha';
import {  QPP_THEME } from './styles/theme.qpp';
import { SAIB_THEME } from './styles/theme.saib';
import { IMISU_THEME } from './styles/theme.imisu';
import { LITC_THEME } from './styles/theme.litc';
import { MOFOMAIN_THEME } from './styles/theme.mof-oman';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  FormsModule,
  ReactiveFormsModule,
  NbToggleModule,
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  LanguageComponent,
  NotificationsComponent,
  UserDropdownComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  ThemeModeComponent,
  LogoComponent,
  LanguageViewComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  SanitizeHtmlPipe,
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES, NbBadgeModule, TranslateModule, RouterModule],
  exports: [CommonModule, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES, HelpComponent, SideMenuAvatarComponent, ThemeModeComponent],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [
            DEFAULT_THEME,
            COSMIC_THEME,
            CORPORATE_THEME,
            DARK_THEME,
            SEC_THEME,
            MOF_THEME,
            GREEN_THEME,
            CITC_THEME,
            IMCTC_THEME,
            MOH_THEME,
            ROYAL_PROTOCOL,
            GOSI_THEME,
            MOE_THEME,
            MOM_THEME,
            ARAMCO_THEME,
            GAMI_THEME,
            ETUAE_THEME,
            GARTNER_THEME,
            SHC_THEME,
            NAZAHA_THEME,
            QPP_THEME,
            SAIB_THEME,
            IMISU_THEME,
            LITC_THEME,
            MOFOMAIN_THEME,
          ],
        ).providers,
      ],
    };
  }
}
