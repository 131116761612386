import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {BreadService} from './bread.service';

@Injectable({
  providedIn: 'root',
})
export class PhishingGuard implements CanActivate {

  result = true;
  constructor(private breadService: BreadService, private router: Router) {
     }
     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const id = route.paramMap.get('id');
      const model = route.paramMap.get('model');
      if (state.url.includes('phishing_campaign') ||
      state.url.includes('pagetemplate') ||
      (id !== null && id === 'phishing') ||
      (model !== null && model === 'phishing_campaign') ) {
      this.breadService.getData('settings').subscribe(
      res => {
      if (res[0].custom_phishpot !== 1 ) {
      this.router.navigate(['/pages/not_allowed', 20]);
      }
      },
      );
      }
      return true;
      }

  }
