import { ThemeSettingsService } from './../../../services/theme-settings.service';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" state="compacted" tag="menu-sidebar" responsive start>
        <ngx-side-menu-avatar>
        </ngx-side-menu-avatar>
        <div class="toggle-wrapper">
          <ngx-theme-mode [showTitle]="true" [showHorizontalLine]="true"></ngx-theme-mode>
        </div>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column [style.background]="this.watermarkUrl" class="background-image">
        <ng-content select="router-outlet, div"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed *ngIf="enableFooter">
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit {
  watermarkUrl: string;
  checked: boolean = false;
  enableFooter: boolean = false;

  constructor(
    private themeSettingsService: ThemeSettingsService,
  ) {
    this.themeSettingsService.watermark$.subscribe((url) => {
      this.watermarkUrl = `url(${url})`;
    });
  }

  ngOnInit() {
    this.themeSettingsService.enableFooterForHome$.subscribe((res) => {
      this.enableFooter = res;
    })
  }

}
