import { NbThemeService } from '@nebular/theme';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { tap, pluck, map } from 'rxjs/operators';
import { NbAuthService } from '../@framework/auth/public_api';

export interface SystemThemeSettings {
    logo?: string | File;
    watermark?: string | File;
    default_theme?: string;
    enable_theme_mode?: boolean;
    enable_footer_for_login?: boolean;
    enable_footer_for_home?: boolean;
}

export interface UserThemeSettings {
    theme_mode?: 'default' | 'dark'; // Only use in requests to change to default mode
}
export interface SystemTheme {
    name: string;
    title_en: string;
    title_ar: string;
}
@Injectable({ providedIn: 'root' })
export class ThemeSettingsService {
    authenticated: boolean = false;
    // Using a ReplaySubject with state buffer of size 1 as a global state manager
    private __sysTheme$: ReplaySubject<SystemThemeSettings> = new ReplaySubject<SystemThemeSettings>(1);
    private __userTheme$: ReplaySubject<UserThemeSettings> = new ReplaySubject<UserThemeSettings>(1);
    private __themeLoadedNotifier$: Subject<null> = new Subject<null>();

    get logo$(): Observable<SystemThemeSettings['logo']>  {
        return this.__sysTheme$.pipe(
            pluck('logo'),
        );
    }

    get watermark$(): Observable<SystemThemeSettings['watermark']> {
        return this.__sysTheme$.pipe(
            pluck('watermark'),
        );
    }

    get defaultTheme$(): Observable<SystemThemeSettings['default_theme']> {
        return this.__sysTheme$.pipe( pluck('default_theme') );
    }

    get enableThemeMode$(): Observable<SystemThemeSettings['enable_theme_mode']> {
        return this.__sysTheme$.pipe( pluck('enable_theme_mode') );
    }

    get enableFooterForLogin$(): Observable<SystemThemeSettings['enable_footer_for_login']> {
        return this.__sysTheme$.pipe( pluck('enable_footer_for_login') );
    }

    get enableFooterForHome$(): Observable<SystemThemeSettings['enable_footer_for_home']> {
        return this.__sysTheme$.pipe( pluck('enable_footer_for_home') );
    }

    get themeMode$(): Observable<UserThemeSettings['theme_mode']> {
        return this.__userTheme$.pipe(
            pluck('theme_mode'),
        );
    }

    get themeLoaded$(): Observable<null> {
        return this.__themeLoadedNotifier$.asObservable();
    }

    constructor(
        private http: HttpClient,
        private nbThemeService: NbThemeService,
        private authService: NbAuthService,
    ) {
        this.fetchSystemThemeSettings().subscribe((settings) => this.handleSystemThemeSettingsChange(settings));
        this.authService.isAuthenticatedLocal().subscribe(res => {
            if (res === true) {
                this.fetchUserThemeSettings().subscribe((settings) => this.handleUserThemeSettingsChange(settings));
                this.authenticated = true;
            }
        });
    }

    fetchSystemThemeSettings(): Observable<SystemThemeSettings> {
        return this.http.get<SystemThemeSettings>(`/app/theme_settings`).pipe(
            map((settings: SystemThemeSettings) => {
                settings.logo = `/app/${settings.logo}`;
                settings.watermark = `/app/${settings.watermark}`;
                return settings;
            }),
        );
    }

    handleSystemThemeSettingsChange(settings: SystemThemeSettings) {
        // Publish new theme settings
        this.__sysTheme$ && this.__sysTheme$.next(settings);
        if (this.authenticated === false) {
            settings.default_theme && this.nbThemeService.changeTheme(settings.default_theme);
        }
        this.__themeLoadedNotifier$ && this.__themeLoadedNotifier$.next();
    }

    setSystemThemeSettings(settings: SystemThemeSettings) {
        const formData = new FormData();
        for (const [k, v] of Object.entries(settings)) {
            v !== undefined && v !== null && formData.append(k, v instanceof File ? v : `${v}`);
        }
        formData.append('_method', 'PUT');

        return this.http.post<SystemThemeSettings>(
                '/app/theme_settings',
                formData,
            );
    }

    resetSystemThemeSettingsToDefaults() {
        return this.http.post<SystemThemeSettings>(
            '/app/theme_settings/reset',
            null,
        );
    }

    fetchUserThemeSettings(): Observable<UserThemeSettings> {
        return this.http.get<UserThemeSettings>(`/app/my/theme_settings`);
    }

    handleUserThemeSettingsChange(settings: UserThemeSettings) {
        // Change system mode if mode changed
        // TODO : The check of theme_mode should be inside the subscribe
        settings.theme_mode && this.defaultTheme$.subscribe((defaultTheme) => {
            this.nbThemeService.changeTheme(settings.theme_mode === 'dark' ? 'dark' : defaultTheme);
            // Publish new theme settings
            this.__userTheme$ && this.__userTheme$.next(settings);
        });
    }

    setUserThemeSettings(settings: UserThemeSettings) {
        return this.http.put<UserThemeSettings>('/app/my/theme_settings', settings).pipe(
            tap(res => this.handleUserThemeSettingsChange(res)),
        );
    }

    getSystemDefaultThemes(): Observable<SystemTheme[]> {
        return this.http.get<SystemTheme[]>('/app/theme');
    }
}
